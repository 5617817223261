import React from "react";
// import config from "../../data/SiteConfig";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import { graphql } from "gatsby";

const MentionsLegalesPage = ({ data: gqlData }) => {
  const { directus } = gqlData;
  return (
    <Layout>
      <div className="h-20 md:h-28"></div>
      <div className="mentions-container">
        <Helmet title={`Mentions légales`} />
        <div className={`mx-auto max-w-6xl w-full pt-8 pb-32 px-4`}>
          <h1 className="text-center">Informations légales</h1>
          <hr className="bg-red-500 border-none h-2" />
          <div
            className="mt-6 text-editor"
            dangerouslySetInnerHTML={{
              __html: `${directus.settings.mentions}`,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default MentionsLegalesPage;

export const pageQuery = graphql`
  query {
    directus: allDirectus {
      settings {
        id
        mentions
      }
    }
  }
`;
